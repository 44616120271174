import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";

// Loading
const loading = () => (
  <div>
    <span>Loading...</span>
  </div>
);

// Containers
const Layout = React.lazy(() => import("./containers/Layout"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/" name="Layout" render={(props) => <Layout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App
